.react-autosuggest__container {
    position: relative;
    display: inline-flex;
    flex-direction: column;
  }
  
  .react-autosuggest__input {
    width: "100%";
    height: 18px;
    padding: 10px 10px 10px;
    font-size: 16px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 39px;
    width: auto;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 10;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
    
  }
  
  .footer {
    margin: 9px 20px;
    font-size: 12px;
    color: #777;
  }

  .invoice-info-container {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 16px;
  }

  .order-info-container {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-top: 20px;
  }

  .aditional-info-container {
    display: flex;
    flex-direction: column;
   /*  border: 1px solid rgba(0, 0, 0, 0.12); */
    border-radius: 4px;
    box-shadow: none !important;
  }

  .MuiFormControlLabel-label{
    font-weight: bold;
  }