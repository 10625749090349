@media (max-width: 700px ){
    .header-container-rec{
        flex-direction: column !important;
        align-items: center;
        margin-bottom: 1.5rem;
    }

}
@media  (min-width: 600px ) {
    .padding-general{
        padding-left: 25px !important;
    }
}
@media  (max-width: 600px ) {
    .padding-general{
        padding-left: 0px !important;
    }
}