.ResultMessage{
    color: #2db967b7;
    font-size: small;
    list-style: none;
 }
  
 .FileItem {
    display: flex;
    align-items: flex-start;
  }

  .iconContainer {
    margin-top: 15px;
  }
  
  .btnCerrar{
    transform: rotate(0deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-left: auto;
  }
  