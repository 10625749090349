.padd {
  padding-top: 20px;
}
@media (max-width: 600px ){ /*30 x 16px = 480px*/
    .cardSmall {
       font-size: small !important;
    }
}
.btnFecha {
  align-content: "center";
  justify-content: "center";
  font-size: 80% !important;
  height: 30;
  margin-left: 20;
  width: max-content;
}

.sizeCalendar {
  width: 100% !important;
}
.linea{
    box-shadow: 10px 0 5px -2px #e6e6e6;
    }

.headTable{
        font-weight: bold;
        text-align: center;

    }

.btnDescarga{
    background-color: none;
    border: none;
    box-shadow: none;
    all: unset;
    }

.iconCheck{
    font-size: 20px;
    padding-left: 7px;
}
.btnExcel{
    text-transform: none;
}
.tarjetas{
    background-color: whitesmoke;
    padding: 8px;

}
.card-icon-setting{
  transform: translate(-50%, -90%);
    box-shadow: 0 0 0 4px white;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin: 0 auto;
    display: flex;
    background: #f0f4f7;
    justify-content: center;
    align-items: center;
}