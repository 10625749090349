body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Manrope',"Helvetica", "Arial", sans-serif !important;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Manrope',"Helvetica", "Arial", sans-serif !important;
}

.rec.rec-dot.cJeRRR {
    background-color: rgb(69 181 103 / 50%);
    box-shadow: 0 0 1px 3px rgb(16 124 65);
}

.rec.rec-dot:hover {
    box-shadow: 0 0 1px 3px rgb(16 124 65);
}

#filter-bar .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-size: 12px;
    margin-top: -3px;
}

#filter-bar .MuiOutlinedInput-root {
    position: relative;
    border-radius: 4px;
    height: 31px;
}

.MuiCheckbox-root{
  color: #679A60 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover{
  background: transparent !important;
}

.MuiIconButton-colorSecondary:hover{
  background: transparent !important;
}

.react-autosuggest__container{
  width: 100% !important; 

}

.react-autosuggest__input {
    padding: 10px 10px 10px !important;
    width: 100% !important; 
    height: 58px !important;
    /* margin: 10px 0px !important; */
    transition: all 0.5s ease !important;
    border: 1px solid #736D6D !important;
}

.react-autosuggest__input:focus {
  padding: 10px 10px 10px !important;
  width: 100% !important; 
  border: 2px solid #679A60 !important;
}

.react-autosuggest__suggestions-container{
  margin-top: 20px;
}

.MuiStepIcon-root.MuiStepIcon-completed{
  color:#985f7b !important;
}

.MuiStepIcon-root.MuiStepIcon-active{
  color:#679A60 !important;
}