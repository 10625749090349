.btnPagar{
        text-decoration: none;
        border: none;
        color: white;
        width: 150px;
        padding: 10px 20px 10px 20px;
        text-align: center;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        border-radius: 10px;
        background-color: #21a366;
        text-decoration: none;
}


.cardRight{    
    border-left: 1px solid rgb(211, 204, 204);
}

.timer{
   
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%);
    background-color: #91e4be !important;
    width: 50px;
    border-radius: 5px !important;
    color: black !important;
    
}
.letrasTimer{ 
  margin-right: 15px;
}
.texto{
    font-size: 15px !important;
    font-weight: 600 !important;
}